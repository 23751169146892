.index {
  padding: 1rem;
  padding-top: 11rem;
  background: #414bea url('../assets/bg@2x.jpg') top center no-repeat;
  background-size: 640px auto;
  > .body {
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 1rem;
    .preface {
      > .body {
        padding-bottom: 1rem;
      }
      > .footer {
        padding-bottom: 1rem;
      }
    }
    .categories {
      > .item {
        margin-top: .5rem;
        border-radius: @radius-md;
        overflow: hidden;
        &:active {
          transform: scale(.975);
        }
        > img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}