.list {
  > .header {
    > img {
      display: block;
      width: 100%;
      height: 10rem;
      object-fit: cover;
    }
  }
  > .body {
    border-top: 1px solid #aaaaaa;
    background-color: #fafafa;
  }
  > .footer {
    padding: 1rem;
    background-color: #fafafa;
  }
  .grid {
    padding: .5rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    > .item {
      width: 50%;
      > .body {
        padding: .5rem;
        > .thumb {
          background-color: #ffffff;
          border: 1px solid #dddddd;
          > img {
            display: block;
            margin: auto;
            width: 85%;
            height: 8rem;
            object-fit: contain; 
          }
        }
        > .type {
          margin-top: .25rem;
          font-size: .75rem;
          color: @color-text-secondary;
        }
        > .description {
          font-size: .875rem;
          line-height: 1.25;
        }
      }
    }
  }
}