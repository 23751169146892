.introduction {  
  background-color: #ffffff;
  > .header {
    height: 12rem;
    background: transparent url('../assets/bg@2x.jpg') top center no-repeat;
    background-size: 640px;
  }
  > .body {
    padding: 1rem;
    > h1 {
      margin-top: 0;
      font-size: 1.75rem;
      text-align: center;
    }
  }
  > .footer {
    padding: 1rem;
    padding-top: 0;
  }
}