.app {
  > .footer {
    padding: 1rem 0 2rem 0;
    font-size: .75rem;
    color: #cccccc;
    text-align: center;
    .highlight {
      margin: 0 .25rem;
      color: #ffffff;
    }
  }
}