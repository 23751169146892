@import "~antd-mobile/lib/style/index";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.75;
  color: #333333;
  background-color: #333333;
}

.app {
  max-width: 640px;
  margin: 0 auto;
}

@import "./pages/Details";
@import "./pages/List";
@import "./pages/Introduction";
@import "./pages/Index";
@import "./App";
@brand-primary: #333333;@primary-button-fill-tap: #222222;