.details {
  > .top {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #000000;
  }
  > .header {
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
    background-color: #ffffff;
    > .logo {
      margin-right: 1rem;
      width: 4rem;
      height: 4rem;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    > .description {
      > .name {
        line-height: 1.25;
      }
      > .title {
        margin-top: .125rem;
        font-size: .875rem;
        color: @color-text-secondary;
      }
    }
  }
  > .body {
    background-color: #ffffff;
    > .photos {
      > img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    > .content {
      padding: 1rem;
    }
    > .extra-content {
      padding: 1rem;
    }
  }
  > .footer {
    padding: 1rem;
    background-color: #ffffff;
  }
}